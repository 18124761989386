import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { Alert } from "@mui/material";
import { useState } from "react";
import contactInfoImg from '../../assets/img/cmu-contact-us-envelope.png'




const ContactPage = (event) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState(false);


  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone_number: "",
      message: "",
    },

    validationSchema: yup.object({
      name: yup
        .string()
        .max(20, "Name must be 20 characters or less.")
        .required("Please complete this required field"),
      email: yup
        .string()
        .email("Invalid E-mail address")
        .required("Please complete this required field"),
      message: yup.string().required("Please fill out the message field"),
    }),

    // onSubmit: async (values, { resetForm }) => {
    onSubmit: async (values, {}) => {
      setFormSubmitted(true);
      try {
        const response = await axios({
          method: "post",
          url: "https://cms.codemeup.net/email/contact",
          headers: {
            "x-api-key": "91300060-4db9-4474-93a2-9c7133ba1f49",
          },
          data: {
            name: values.name,
            email: values.email,
            phone_number: values.phone_number,
            message: values.message,
          },
        });
        // TODO: Handle response (200)

        formik.resetForm({});
      } catch (exception) {
        setError(true);
        console.log(exception)
      }
    },
  });

  return (

<div>
<HelmetProvider>
 <Helmet>
 <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
 </Helmet>
 </HelmetProvider>
    <div style={{backgroundColor: "#1A1A1A", alignItems: 'center', display: 'flex'}} className={"contact-page-area-wrapper sp-y sm-top"}>
      <div className="container sp-top">
        <h1 className="contact-us-h1">Contact Us</h1>
          <div>
            <div className="row">
            <div className="col-lg-4-contact-first-box">
                <div style={{alignItems: 'center', color: '#666666'}} className="contact-information contact-method">
                  <div className="contact-info-con">
                  <div style={{marginBottom: '20px', textAlign: 'center'}}><img src={contactInfoImg} alt="codemeup-contact-envelope" /></div>
                    <p style={{fontWeight: '400'}}>
                    We are here to answer any questions you may have.<br />
                    Feel free to contact us, and we will reply as soon as
                    possible.
                  </p>
                  </div>
                </div>
              </div>
            <div className="col-lg-8 col-lg-8-contact-us-form">
                <div className="contact-method">
                  <div className="contact-form-wrap">
                    <form
                      id="contact-form"
                      onSubmit={formik.handleSubmit}
                      className=""
                    >

                      <div className="row">
                        {/* Name input field */}
                        <div className="col-md-12">
                          <div className="single-input-item-2">
                            <label
                              htmlFor="name"
                              className={`block ${
                                formik.touched.name && formik.errors.name
                                  ? "text-red-400"
                                  : ""
                              } `}
                            >
                              {formik.touched.name && formik.errors.name
                                ? formik.errors.name
                                : ""}
                            </label>
                            <p className="text-red-400 "></p>
                            <input
                              type="text"
                              name="name"
                              placeholder="Name"
                              onChange={formik.handleChange}
                              value={formik.values.name}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                        {/* Email input field */}
                        <div className="single-input-item-2 col-md-6">
                          <label
                            htmlFor="email"
                            className={`block ${
                              formik.touched.email && formik.errors.email
                                ? "text-red-400"
                                : ""
                            }`}
                          >
                            {formik.touched.email && formik.errors.email
                              ? formik.errors.email
                              : ""}
                          </label>

                          <p className="text-red-400 "></p>
                          <input
                            type="text"
                            name="email"
                            placeholder="Email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {/* Mobile phone input field */}
                        <div className="single-input-item-2 col-md-6">
                          <label
                            htmlFor="phone_number"
                            className={`block ${
                              formik.touched.phone_number &&
                              formik.errors.phone_number
                                ? "text-red-400"
                                : ""
                            }`}
                          >
                            {formik.touched.phone_number &&
                            formik.errors.phone_number
                              ? formik.errors.phone_number
                              : ""}
                          </label>

                          <p className="text-red-400"></p>
                          <input
                            type="tel"
                            name="phone_number"
                            placeholder="Phone number"
                            onChange={formik.handleChange}
                            value={formik.values.phone_number}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {/* Text area - Message field */}
                        <div className="single-input-item-2 col-md-12">
                          <label
                            htmlFor="message"
                            className={`block ${
                              formik.touched.message && formik.errors.message
                                ? "text-red-400"
                                : ""
                            }`}
                          >
                            {formik.touched.message && formik.errors.message
                              ? formik.errors.message
                              : ""}
                          </label>

                          <p className="text-red-400 "></p>
                          <div className="textarea-container">
                          <textarea
                            name="message"
                            placeholder="Message"
                            colls="30"
                            rows="7"
                            onChange={formik.handleChange}
                            value={formik.values.message}
                            onBlur={formik.handleBlur}
                            className="styled-textarea"
                          />
                          </div>
                        </div>

                        {formSubmitted && !error ? (
                          <Alert
                            className="contact-form-success-message"
                            severity="success"
                          >
                            Your message has been sent!
                          </Alert>
                        ) : 
                          formSubmitted &&
                          error ?
                            <Alert
                              className="contact-form-success-message"
                              severity="error"
                            >
                              Oops, something went wrong!
                            </Alert>
                          : <></>
                        }
                        <div className="submit-button-container">
                          <button
                            // onClick={notify}
                            type="submit"
                            value="send message"
                            className="btn-outline"
                            data-umami-event="Send message button"
                          >
                            Send message
                          </button>
                        </div>
                        {/* <ToastContainer /> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>
  );
};

export default ContactPage;
