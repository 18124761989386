import React from 'react'
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const HeaderConfig = () => {

    const location = useLocation()

    const MobileMenuHandler = () => {
        const offCanvasMenu = document.querySelector('.off-canvas-menu')
        offCanvasMenu.classList.add('active')
    }

    const isActive = (path) => location.pathname === `${process.env.PUBLIC_URL + path}`
    

     return (
         <div className="header-action mt-lg-0">
            <Link 
            style={
                isActive('/contact') 
                    ? { background: 'linear-gradient(90deg, #E12426 0%, #C61F20 69%, #B41214 100%)' } 
                    : {}
            } 
            to={`${process.env.PUBLIC_URL + '/contact'}`} className="btn-navigation-menu">Contact us</Link>
             <button onClick={MobileMenuHandler} className="btn-menu d-lg-none" id="burger" aria-label="Burger"><i className="fa fa-bars"/></button>
       </div>
     )
}

export default HeaderConfig;