import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import leftArrow from "../../../assets/img/arrow_red_black_left.svg"
import rightArrow from "../../../assets/img/arrow_red_black_right.svg"


const Slider = () => {

    const [animationTriggered, setAnimationTriggered] = useState(false)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const checkMobile = () => setIsMobile(window.innerWidth <= 768);
      checkMobile();
      window.addEventListener("resize", checkMobile);
      return () => window.removeEventListener("resize", checkMobile);
    }, []);

        return (
            <div className={'slider-area sm-top'}>
            <motion.div
              className="slider-content col-xl-12"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                overflow: "hidden",
                flexDirection: "column",
                backgroundColor: "#000000",
                paddingTop: '100px',
              }}
              onMouseEnter={() => {
                if (!isMobile) {
                  setAnimationTriggered(true);
                }
              }}
            >

              {/* Gradient element absolute */}
              {!isMobile && (
                <div
                  style={{
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "radial-gradient(circle, #3a3a3a 0%, #262626 25%, #1d1d1d 55%, #131313 100%)",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    opacity: animationTriggered ? 0.8 : 0,
                    transition: "opacity 1.6s ease-in-out",
                    pointerEvents: "none",
                  }}
                />
              )}

              {/* Left Arrow */}
              {!isMobile && (
                <motion.img
                      src={leftArrow}
                      alt="Left Arrow"
                      style={{
                          position: "absolute",
                          width: "15.5vw",
                          height: "auto",
                          left: "33%",
                      }}
                      initial={{ x: 0 }}
                      animate={
                        animationTriggered
                            ? { x: "-142%" }
                            : {}
                      }
                      
                      transition={{
                          duration: 1.5,
                          ease: "easeInOut",
                      }}
                  />
                )}

                {/* Right Arrow */}
                {!isMobile && (
                  <motion.img
                      src={rightArrow}
                      alt="Right Arrow"
                      style={{
                          position: "absolute",
                          width: "15.5vw",
                          height: "auto",
                          right: "33%",
                      }}
                      initial={{ x: 0 }}
                      animate={
                        animationTriggered
                            ? { x: "142%" }
                            : {}
                      }
                      transition={{
                          duration: 1.5,
                          ease: "easeInOut",
                      }}
                  />
                )}

              {/* Main Title (h2) */}
              <motion.h1
                style={{
                  fontWeight: "600",
                  textAlign: "center",
                  position: "relative",
                  opacity: isMobile ? 1 : 0,
                }}
                initial={{ opacity: isMobile ? 1 : 0 }}
                animate={
                  animationTriggered
                    ? { opacity: 1 }
                    : {}
                }
                transition={{
                  duration: 2,
                  ease: "easeInOut",
                }}
              >
                Building the Future with Innovative Digital Solutions
              </motion.h1>

              {/* Second Paragraph */}
              <motion.p
                style={{
                  fontWeight: "500",
                  textAlign: "center",
                  position: "relative",
                  color: "transparent",
                  opacity: isMobile ? 1 : 0,
                  width: "100%",
                  background: "linear-gradient(90deg, #E12426 0%, #C61F20 69%, #B41214 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                initial={{ opacity: isMobile ? 1 : 0 }}
                animate={
                  animationTriggered
                    ? { opacity: 1 }
                    : {}
                }
                transition={{
                  duration: 2,
                  ease: "easeInOut",
                }}
                className="slider-subheader selection-red"
              >
                OUR TEAM IS COMMITTED TO SUCCESS!
              </motion.p>

              {/* Third Paragraph */}
              <motion.p
                style={{
                  fontWeight: "300",
                  textAlign: "center",
                  position: "relative",
                  opacity: isMobile ? 1 : 0,
                  marginTop: "-20px",
                }}
                initial={{ opacity: isMobile ? 1 : 0 }}
                animate={
                  animationTriggered
                    ? { opacity: 1 }
                    : {}
                }
                transition={{
                  duration: 2,
                  ease: "easeInOut",
                }}
                className="slider-paragraph"
              >
                We firmly believe in the importance of setting ambitious goals while maintaining flexibility in our methods.
              </motion.p>
            </motion.div>
            </div>
        )
    }

export default Slider;