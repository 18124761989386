import React from 'react'
import backgroundImg from '../../assets/img/arrow_down.svg'
import engineeringTeam from '../../assets/img/cmu_engineering_taem_icon.png'
import { useEffect, useRef } from "react"


const Services = ({classes}) => {

    const animationContainerRef = useRef(null)

    useEffect(() => {
        const handleScroll = () => {
            if (animationContainerRef.current) {
                const elements = animationContainerRef.current.querySelectorAll(".animate")
                elements.forEach((el) => {
                    const rect = el.getBoundingClientRect()
                    if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                        el.classList.add(el.dataset.animation)
                    }
                });
            }
        };
        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
    }, [])


    return (
        <div style={{ 
            background: '#111111', color: '#fff', borderBottom: '25px solid',
            borderImage: 'linear-gradient(90deg, #E12426 0%, #C61F20 69%, #B41214 100%)',
            borderImageSlice: 1 
            }} 
            className={`service-area-wrapper ${classes}`}
            ref={animationContainerRef}
            >
            <div
                className="service-area-top"
                style={{
                    alignItems: "center",
                    backgroundImage: `url(${backgroundImg})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'top center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-xl-8 m-auto text-center">
                                <h2 
                                    className="why-work-with-us-h2"
                                    style={{
                                        color: "#ffffff",
                                        textAlign: "center",
                                        background: "linear-gradient(90deg, #E12426 0%, #C61F20 69%, #B41214 100%)",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    WHY WORK WITH US?
                                </h2>
                                <div className="mb-4">
                                <img className="why-work-with-us-ico" src={engineeringTeam} alt="Why Work With Us" />
                                </div>                        
                            </div>
                        </div>
                    </div>
            </div>

            <div className="service-content-area">
                <div style={{textAlign: 'center'}} className="container">
                    
                        {/* Why work with us first row */}
                        <div className="row why-work-with-us-row">
                        <div className="col-lg-4 col-md-6 animate" data-animation="fade-in-left">
                            <h4 className="why-work-with-us-h4" style={{color: '#ffffff'}}>Global Expertise</h4>
                            <p className="why-work-with-us-paragraph">Our company has an extensive knowledge base working on TV products for 20+ operators all around the world.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 animate" data-animation="fade-in-down">
                            <h4 className="why-work-with-us-h4" style={{color: '#ffffff'}}>Expert Engineering Team</h4>
                            <p className="why-work-with-us-paragraph ">We employ a highly experienced engineering team of software developers, QA engineers, and product owners.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 animate" data-animation="fade-in-right">
                            <h4 className="why-work-with-us-h4" style={{color: '#ffffff'}}>Massive Impact</h4>
                            <p className="why-work-with-us-paragraph">We have delivered TV products and features which are currently running on millions of active devices and are used by millions of active users.</p>
                        </div>

                        {/* Why work with us second row */}
                        <div className="col-lg-4 col-md-6 animate" data-animation="fade-in-left" >
                            <h4 className="why-work-with-us-h4" style={{color: '#ffffff'}}>Agile</h4>
                            <p className="why-work-with-us-paragraph">We utilise the Agile approach and adapt to your processes to produce the best solutions on time and within budget.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 animate" data-animation="fade-in-up" >
                            <h4 className="why-work-with-us-h4" style={{color: '#ffffff'}}>First-Class Service</h4>
                            <p className="why-work-with-us-paragraph">Our commitment to providing exceptional customer service is what motivates us every day. We make sure every detail is handled with care making our clients feel like we are an integral part of their team.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 animate" data-animation="fade-in-right">
                            <h4 className="why-work-with-us-h4" style={{color: '#ffffff'}}>Onsite Developer</h4>
                            <p className="why-work-with-us-paragraph">Our brilliant pool of developers is continually being expanded.</p>
                        </div>
                        </div>
                </div>
            </div>
        </div>
    );
}

export default Services;