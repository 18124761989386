import React, {Fragment} from 'react'
import Header from '../components/Header'
import Slider from '../components/Slider/home-one'
import About from '../components/About/home-one'
import Features from '../components/Features'
import Services from '../components/Services'
import CallToAction from "../components/CallToAction"
import Footer from "../components/Footer"
import LoginRegister from "../components/LoginRegister"
import MobileMenu from "../components/MobileMenu"
import TransformYourDigitalVisionWithUs from '../components/Transform Your Digital Vision With Us'
import ClientsAndPartners from '../components/ClientsAndPartners'


const HomeOne = () => {
    return (
        <Fragment>
            <Header/ >
            {/* <PageHeader 
            bgImg={require('../assets/img/hero-image-test-1.png')}
            /> */}
            <Slider />
            <About/>
            <Features classes="sp-top"/>
            <Services classes="sm-top-wt"/>
            <div className="App">
      {/* <VerticalTab data={resumeData.jobs} /> */}
    </div>
            {/* <Testimonials/> */}
            {/* <Team/> */}
            {/* <Blog/> */}
            {/* <BrandLogo/> */}
            {/* <Funfact/> */}
            <CallToAction/>
            <TransformYourDigitalVisionWithUs/>
            <ClientsAndPartners/>
            <Footer/>
            <MobileMenu/>
            <LoginRegister/>
        </Fragment>
    );
};

export default HomeOne;