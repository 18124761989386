import React, { useEffect, useState } from 'react'
import marketPresenceImg from '../../assets/img/codemeup-market-presence.svg'


function TransformYourDigitalVisionWithUs() {

    const [isVisible, setIsVisible] = useState(false)
  
    useEffect(() => {
        const element = document.querySelector('.image-container')
        if (!element) return
    
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true)
                }
            },
            { threshold: 0.2 }
        )
        observer.observe(element)
        return () => observer.disconnect()
    }, [])


return (
        <div 
        style={{
                position: "relative",
                display: "block",
                height: "741",
                textAlign: "center",
                background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.05))",
                backgroundColor: "#1a1a1a"
            
        }}
        className="sp-y" 
        >
                <div style={{textAlign: 'left', display: 'flex', flexWrap: 'wrap'}} className="container">
                 {/* Left Column */}
                    <div class="col-lg-6 col-md-12">
                        <h2 className="transform-your-digital-vision-with-us-h2" style={{ color: '#ffffff', marginBottom: '40px'}}>Transform Your <br/><span style={{fontWeight: '300'}}>Digital Vision With Us</span></h2>
                        <p className="transform-your-digital-vision-with-us-paragraph" style={{color: 'rgba(255, 255, 255, 0.75)'}}>We empower businesses and individuals through innovative and transformative software solutions, perfectly tailored to fit their needs, enhance productivity and drive sustainable growth.</p>
                        <div className="transform-your-digital-vision-growth-container">
                        <div className="transform-your-digital-vision-elements" style={{border: '3px solid #E12426', borderRadius: '25px'}}>
                            <h3 className="transform-your-digital-vision-elements-h3" style={{color: '#ffffff', letterSpacing: '-0.5px'}}>20 +</h3>
                            <p style={{color: '#E12426', fontWeight: '400', letterSpacing: '-0.5px'}}>COMPLETED AND LAUNCHED PRODUCTS</p>
                        </div>
                        <div className="transform-your-digital-vision-elements" style={{border: '3px solid #E12426', borderRadius: '25px'}}>
                            <h3 className="transform-your-digital-vision-elements-h3" style={{color: '#ffffff', letterSpacing: '-0.5px'}}>2 000 000 +</h3>
                            <p style={{color: '#E12426', fontWeight: '400', letterSpacing: '-0.5px'}}>ACTIVE USERS</p>
                        </div>
                        </div>
                    </div>
                    {/* Right Column */}
                    <div style={{ textAlign: 'center'}} class="col-lg-6 col-md-12 market-presence-container">
                        <div className={`image-container ${isVisible ? 'visible' : ''}`}>
                            <p>Market presence</p>
                            <img src={marketPresenceImg} alt="cmu-market-presence" className="code-me-up-market-presence-globe" />
                        </div>
                    </div>
                </div> 
        </div>
    );
}
export default TransformYourDigitalVisionWithUs;