import React, { useState, useEffect, useRef } from 'react'

function FeatureItem(props) {
	const { title, img, hoverImg, index } = props
	const [isHovered, setIsHovered] = useState(false)
	const [isVisible, setIsVisible] = useState(false)
	const transitionRef = useRef(null)
	const isHoverable = [0, 2, 4].includes(index)

	useEffect(() => {
		const currentRef = transitionRef.current;
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
				const delay = window.innerWidth <= 768 ? index * 150 : index * 500
				setTimeout(() => setIsVisible(true), delay)
				}
			},
		  { threshold: 0.5 } 
		)
	
		if (currentRef) {
		  observer.observe(currentRef)
		}
		return () => {
		  if (currentRef) {
			observer.unobserve(currentRef)
		  }
		}
	  }, [index])

	return (
		<div
			ref={transitionRef}
			className={`feature-item-container ${isHoverable ? "feature-item-hoverable" : ""}`}
			onMouseEnter={() => isHoverable && setIsHovered(true)}
			style={{
				opacity: isVisible ? 1 : 0,
				transform: isVisible ? "scale(1)" : "scale(0.5)",
				transition: "opacity 1.5s ease, transform 1.5s ease",
			}}
		>
			<img
				className={`what-we-do-item-image ${[1, 3].includes(index) ? "what-we-do-item-chevron-ico" : ""}`}
				src={require(`../../assets/img/${isHoverable && isHovered ? hoverImg : img}`)}
				alt="feature"
				style={{ borderRadius: isHoverable ? "50%" : "0" }}
			/>
			<h3 className="what-we-do-item-title">{title}</h3>
		</div>
        
	)
}

export default FeatureItem