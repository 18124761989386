import React from 'react'
import Text from "../UI/Text"
import Widget from "../UI/Widget"
import {Link} from "react-router-dom"
import Logo from '../../assets/img/code_me_up_logo.png'
import socialNetworks from '../../data/SocialNetworks/socials'
import linkedinIco from '../../assets/img/cmu-linkedin-ico.png'

function Footer() {
    return (
        <footer className="footer-area sp-top" style={{ backgroundColor: '#0E0E0E'}}>
            <div style={{display: 'block', alignItems: 'center', paddingBottom: '60px'}} className="container">
                <div className="row mtn-40">
                <div className="col-lg-4 order-4 order-lg-0 col-md-12">
                        <div className="widget-item">
                            <div className="about-widget">
                                <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                                    <img src={Logo} alt="Logo"/>
                                </Link>

                                <Widget title="">  
                                <footer-address style={{color: "#d9d9d9"}}>
                        
                        Dušana Vukasovića 82, Belgrade<br/>
                        {/* <a href="https://www.codemeup.net" target="_blank" rel="noreferrer">codemeup.net</a> */}
                         {/* <br/> */}
                        <a href="mailto:office@codemeup.net">office@codemeup.net</a>
                        <div className="member-social-icons mt-10">
        {
            socialNetworks.map(social=>(
                <a key={social.id} href={`https://${social.networkName}.com/${social.username}`} target="_blank" rel="noopener noreferrer" aria-label="Connect with Us on LinkedIn" >
                    <img style={{width: '33px'}} src={linkedinIco} alt="linkedin-ico-footer"/>
                </a>
            ))
        }
    </div>
                    </footer-address>
                    </Widget>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-4 ml-auto">
                        <Widget style={{color: '#666666', marginBottom: '20px'}} title="SERVICES">
                           
            
                                {/* </List> */}
                            <ul style={{ color: '#E12426', fontWeight: '400'}} classes="widget-list">
                                <li classname="footer-services-li">Android TV App Development</li>
                                <li classname="footer-services-li">SmartHome, IoT and Embedded Systems</li>
                                <li classname="footer-services-li">OTT Media player Development</li>
                                <li classname="footer-services-li">Backend Services and APIs</li>
                                <li classname="footer-services-li">CI/CD, QA and Test Automation</li>
                                <li classname="footer-services-li">Analytics and Advertising</li>
                            
                            </ul>
                        </Widget>
                    </div>

                    <div className="col-md-12 col-lg-4 footer-text-centered">
                        <Widget>
                            <Text style={{fontSize: '18px',}}> 
                                Delivered TV products and features
                                running on millions of active devices
                                that are used by millions of daily
                                active users.
                            </Text>
                        </Widget>
                    </div>
                </div>

            </div>
           {/* Red Footer Div */}
           <div style={{ background: 'linear-gradient(90deg, #E12426 0%, #C61F20 69%, #B41214 100%)', height: '50px' }}>
                <div style={{
                    width: 'min(90vw, 1600px)',
                    margin: '0 auto',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '10px'
                }}>
                    <p className="footer-copyright">
                        &copy; {new Date().getFullYear()} Code Me Up. All Rights Reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;