import React, { Fragment } from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import MobileMenu from "../components/MobileMenu"
import backgroundImg from '../assets/img/our-story-code-me-up-cover.png'
import backgroundChevronImg from '../assets/img/arrow-red-our-story-codemeup.svg'

const PageAbout = () => {
	return (
		<Fragment>
			<Header />
            <div className="page-header-area bg-img" 
				style={{
					backgroundImage: `url(${backgroundImg})`,
					backgroundPosition: 'center',
				}}
			>
				{/* Chevron Wrapper (Absolute Positioned) */}
                <div 
                    className="chevron-wrapper">
                    <img 
                        src={backgroundChevronImg} 
                        alt="Chevron"
                        style={{
                            width: '100%',
                        }}
                    />
                </div>
				<div  className="container">
					<div className="row">
						{/* <div className="col-lg-10 col-xl-8 m-auto text-center"> */}
						<div className="m-auto text-center">
							<div className="page-header-content-inner">
								<div className="page-header-content col-xl-7_5">
									<h1 lassName="our-story-h1">Our Story</h1>
									<p className="our-story-paragraph">
									Code Me Up is a global consulting and software engineering company that empowers the world's prominent IT companies with innovative and sophisticated engineering services.<br /><br />
									Founded in 2021 in Belgrade, Serbia the company has consultancy offices in New York and London, as well as numerous development centers in Serbia and Thailand.<br /><br />
									The company's area of work spans a multitude of domains with primary focus on Media including Android and digital TV in general. The company approaches its clients with impetus not only to solve challenges but also to create future opportunities, while providing the ultimate growth platform for its talented team.<br /><br />
									With the breadth of its expertise and the size of its talent pool in regard to Android and TV endeavors, Code Me Up is able to tackle even the most pressing technological challenges of today and partner with the boldest companies worldwide in pushing the boundaries of technology and turning the implausible into everyday reality.       
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<Footer />
			{/* <LoginRegister /> */}
			<MobileMenu />
		</Fragment>
	);
};

export default PageAbout;