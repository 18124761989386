import React from 'react'
import backgroundImg from "../../assets/img/cmu-core-domain-of-expertise-cover.png"
import androidTvIco from '../../assets/img/android_tv_icon_cmu.svg'
import smartHomeIco from '../../assets/img/smart_home_icon_cmu.svg'
import playerIco from '../../assets/img/player_icon_cmu.svg'
import webAppIco from '../../assets/img/web_app_icon_cmu.svg'
import tedtAndAutomationIco from '../../assets/img/qa_icon_cmu.svg'
import advertisingIco from '../../assets/img/advertising_icon_cmu.svg'


function CallToAction() {
return (
        <div 
        style={{
                textAlign: "center",
                backgroundImage: `url(${backgroundImg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
                backgroundRepeat: 'no-repeat'
            
        }}
        className="sp-y blob core-domain-of-expertise-main-container" 
        >
            <div className="container">
                <div className="footer-top-content">
                    <div className="row text-center">
                        <div className="col-lg-8 col-xl-8 m-auto text-center">
                            <h2 
                            className="core-domain-of-expertise-h2"
                            style={{
                                color: "#ffffff",
                                textAlign: "center",
                            }}>
                                    <span style={{fontWeight: '300'}}>Core Domain of</span> Expertise
                                </h2>
                    
                        </div>
                    </div>
                </div>
            </div>
                <div style={{textAlign: 'left'}} className="container">
                    
                  
            {/* First Row */}
            <div className="core-domain-of-expertise-row">
                <div className="col-md-6-core-domain core-domain-of-expertise-box" style={{ backgroundColor: '#1c1c1c', borderRadius: '25px', color: 'rgba(255, 255, 255, 0.75)', fontSize: '22px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img className="core-domain-of-expertise-ico" src={androidTvIco} alt="Android TV App Development" style={{ marginRight: '15px' }} />
                        <h4 style={{ color: '#ffffff' }}>ANDROID TV APP DEVELOPMENT</h4>
                    </div>
                    <p className="core-domain-of-expertise-paragraph" style={{marginTop: '20px'}}>Unlock the full potential of Android TV with our expert development services. Whether you're a content provider, business, or an individual, we offer tailor-made solutions to create captivating and interactive Android TV applications. From concept to execution, we bring your ideas to life.</p>
                </div>
                <div className="col-md-6-core-domain core-domain-of-expertise-box" style={{ backgroundColor: '#1c1c1c', borderRadius: '25px', color: 'rgba(255, 255, 255, 0.75)', fontSize: '22px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img className="core-domain-of-expertise-ico" src={smartHomeIco} alt="Smartphone, IOT and Embedded Systems" style={{marginRight: '15px' }} />
                        <h4 style={{ color: '#ffffff' }}>SMARTHOME, IOT AND EMBEDDED SYSTEMS</h4>
                    </div>
                    <p className="core-domain-of-expertise-paragraph" style={{marginTop: '20px'}}>Transform your home with cutting-edge Smart Home and IoT solutions. We specialize in crafting seamless, connected experiences that enhance convenience, security, and energy efficiency.</p>
                </div>
                <div className="col-md-6-core-domain core-domain-of-expertise-box" style={{ backgroundColor: '#1c1c1c', borderRadius: '25px', color: 'rgba(255, 255, 255, 0.75)', fontSize: '22px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img className="core-domain-of-expertise-ico" src={playerIco} alt="OTT Media Player Development and Integration" style={{ marginRight: '15px' }} />
                        <h4 style={{ color: '#ffffff' }}>OTT MEDIA PLAYER DEVELOPMENT AND INTEGRATION</h4>
                    </div>
                    <p className="core-domain-of-expertise-paragraph" style={{marginTop: '20px'}}>Elevate your media streaming experience with our OTT media player solutions. We design and develop user-friendly, feature-rich applications that deliver your content to a global audience. We ensure your OTT platform stands out in the digital entertainment landscape.</p>
                </div>


                {/* Second Row */}
                <div className="col-md-6-core-domain core-domain-of-expertise-box" style={{ backgroundColor: '#1c1c1c', borderRadius: '25px', color: 'rgba(255, 255, 255, 0.75)', fontSize: '22px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img className="core-domain-of-expertise-ico" src={webAppIco} alt="Web Applications, Backend Services and Apis" style={{ marginRight: '15px' }} />
                            <h4 style={{ color: '#ffffff' }}>WEB APPLICATIONS, BACKEND SERVICES AND APIS WEB</h4>
                        </div>
                        <p className="core-domain-of-expertise-paragraph" style={{marginTop: '20px'}}>Empower your digital presence with our expert Backend Services and API solutions. We specialize in building robust, scalable, and secure infrastructure to support your applications and websites.</p>
                    </div>
                    <div className="col-md-6-core-domain core-domain-of-expertise-box" style={{ backgroundColor: '#1c1c1c', borderRadius: '25px', color: 'rgba(255, 255, 255, 0.75)', fontSize: '22px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img className="core-domain-of-expertise-ico" src={tedtAndAutomationIco} alt="CI/CD, QA and Test Auutomation" style={{ marginRight: '15px' }} />
                            <h4 style={{ color: '#ffffff' }}>CI/CD, QA AND TEST AUTOMATION</h4>
                        </div>
                        <p className="core-domain-of-expertise-paragraph" style={{marginTop: '20px'}}>Streamline your development pipeline with our CI/CD, QA, and Test Automation services. From automating testing processes to implementing robust CI/CD pipelines, we help you achieve faster, more reliable software development and deployment.</p>
                    </div>
                    <div className="col-md-6-core-domain core-domain-of-expertise-box" style={{ backgroundColor: '#1c1c1c', borderRadius: '25px', color: 'rgba(255, 255, 255, 0.75)', fontSize: '22px', }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img className="core-domain-of-expertise-ico" src={advertisingIco} alt="Analytics and Advertising" style={{ marginRight: '15px' }} />
                            <h4 style={{ color: '#ffffff' }}>ANALYTICS AND ADVERTISING</h4>
                        </div>
                        <p className="core-domain-of-expertise-paragraph" style={{marginTop: '20px'}}>Unlock the power of data-driven insights with our Analytics and Advertising solutions. We provide the tools and expertise to harness actionable data for informed decision-making. From audience analysis to targeted AD campaigns, we help you optimise your marketing strategies and achieve measurable results.</p>
                    </div>
            </div>
            </div>
        <div>
        </div>
        </div>
    )
}

export default CallToAction;