import React from 'react'
import partner1 from '../../assets/img/eicc-partners.png'
import partner2 from '../../assets/img/prime-partners.png'
import partner3 from '../../assets/img/partners-liveroad.png'
import partner4 from '../../assets/img/sdmc-partners.png'
import partner5 from '../../assets/img/targetize-tv-partners.png'
import partner9 from '../../assets/img/sei-robotics-partners.png'
import partner7 from '../../assets/img/skyworth-partners.png'
import partner8 from '../../assets/img/partners-videostrong.png'
import partner6 from '../../assets/img/amlogic-partners.png'




function ClientsAndPartners() {

return (
        <div 
        style={{
                position: "relative",
                display: "block",
                height: "auto",
                textAlign: "center",
                backgroundColor: "#141414"
            
        }}
        className="sp-y" 
        >
                
                {/* Partners Heading */}
            <div className="partners-heading container">
                <h2 className="clients-and-partners-h2" style={{ color: '#ffffff', marginBottom: '40px'}}>Clients and Partners</h2>
                <p>
                At CodeMeUp, we build lasting partnerships with top brands and organizations. 
                Our tailored software solutions help clients improve operations and drive sustainable growth through digital transformation.
                </p>
            </div>

            {/* Partners Grid */}
            <div className="partners-grid">
                <div className="column">
                    <a href="https://www.euroicc.com" target="_blank" rel="noopener noreferrer">
                        <div className="diamond-container partner-1">
                            <img src={partner1} alt="Partner 1" />
                        </div>
                    </a>
                    <a href="https://www.primevideo.com" target="_blank" rel="noopener noreferrer">
                        <div className="diamond-container partner-2">
                            <img src={partner2} alt="Partner 2" />
                        </div>
                    </a>
                </div>

                <div className="column">
                    <a href="https://www.liveroadanalytics.com" target="_blank" rel="noopener noreferrer">
                        <div className="diamond-container partner-3">
                            <img src={partner3} alt="Partner 3" />
                        </div>
                    </a>
                    <a href="https://www.sdmctech.com/" target="_blank" rel="noopener noreferrer">
                        <div className="diamond-container partner-4">
                            <img src={partner4} alt="Partner 4" />
                        </div>
                    </a>
                </div>

                <div className="column">
                    <a href="https://targetize.tv" target="_blank" rel="noopener noreferrer">
                        <div className="diamond-container partner-5">
                            <img src={partner5} alt="Partner 5" />
                        </div>
                    </a>
                    <a href="https://www.amlogic.com" target="_blank" rel="noopener noreferrer">
                        <div className="diamond-container partner-6">
                            <img src={partner6} alt="Partner 6" />
                        </div>
                    </a>
                </div>

                <div className="column">
                    <a href="https://skyworth.com" target="_blank" rel="noopener noreferrer">
                        <div className="diamond-container partner-7">
                            <img src={partner7} alt="Partner 7" />
                        </div>
                    </a>
                    <a href="https://www.videostrong.com" target="_blank" rel="noopener noreferrer">
                        <div className="diamond-container partner-8">
                            <img src={partner8} alt="Partner 8" />
                        </div>
                    </a>
                </div>

                <div className="column">
                    <a href="https://seirobotics.net" target="_blank" rel="noopener noreferrer">
                        <div className="diamond-container partner-9">
                            <img src={partner9} alt="Partner 9" />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}
export default ClientsAndPartners;