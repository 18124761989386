import React from 'react'
import Feature from './FeatureItem'
import FeaturesData from '../../data/Features/features'


const hoverImages = [
    'cmu-what-we-do-product_design-hover-2.png',
    null,
    'cmu-what-we-do-development-hover-2.png',
    null,
    'cmu-what-we-do-deployment-hover-2.png' 
]


function Features({classes}) {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: "#141414",
            overflow: 'hidden',
        }}  
        
        className={`feature-area-wrapper what-we-do-wrapper`}>
            <div className="container what-we-do-container">
                {/* <div className="row mtn-sm-60 mtn-md-5 company-overview-row sm-top-mobile"> */}
                <h2
                className="what-we-do-h2"
                style={{
                    color: "#ffffff",
                    textAlign: "center",
                    background: "linear-gradient(90deg, #E12426 0%, #C61F20 69%, #B41214 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    textTransform: "uppercase",
                }}
                >What do we do?
                </h2>
                <div className="row mtn-md-5 company-overview-row">
                
                    {
                        FeaturesData.map((feature, index) =>(
                            <Feature title={feature.title} key={feature.id} img={feature.imgSrc} hoverImg={hoverImages[index]} index={index} style={{transition: 'transform 1s ease-in-out'}}/>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Features;