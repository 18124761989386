
import React, { useState, useEffect, useRef } from 'react'
import parse from "html-react-parser"
import Content from "../../UI/Content"
import aboutData from '../../../data/About/home-one'
import backgroundImg from '../../../assets/img/code-me-up-who-are-we-cover-1.webp'



const About = () => {

    const [about] = useState(aboutData)
    
	const h2Ref = useRef(null)
	const pRef = useRef(null)
	const [isHeadingVisible, setIsHeadingVisible] = useState(false)
	const [isParagraphVisible, setIsParagraphVisible] = useState(false)

    const [headingAnimationTriggered, setHeadingAnimationTriggered] = useState(false)
    const [paragraphAnimationTriggered, setParagraphAnimationTriggered] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

	// Detect mobile device
    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768) // Adjust breakpoint if needed
        }
        checkMobile()
        window.addEventListener("resize", checkMobile)
        return () => window.removeEventListener("resize", checkMobile)
    }, [])

    useEffect(() => {
        if (isMobile) {
            setIsHeadingVisible(true)
            setIsParagraphVisible(true)
            return // Disable observer on mobile
        }

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.target === h2Ref.current && !headingAnimationTriggered && entry.isIntersecting) {
                    setIsHeadingVisible(true)
                    setHeadingAnimationTriggered(true)
                }
                if (entry.target === pRef.current && !paragraphAnimationTriggered && entry.isIntersecting) {
                    setIsParagraphVisible(true)
                    setParagraphAnimationTriggered(true)
                }
            },
            { threshold: 0.5 }
        )

	const h2Element = h2Ref.current
	const pElement = pRef.current

	if (h2Element) observer.observe(h2Element)
	if (pElement) observer.observe(pElement)

	return () => {
		if (h2Element) observer.unobserve(h2Element)
		if (pElement) observer.unobserve(pElement)
	}
	}, [headingAnimationTriggered, paragraphAnimationTriggered, isMobile])

 
    return (
        <div className="about-area-wrapper sm-top who-are-we-wrapper"
        style={{
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
        }}
        >
        {/* Divider at the top */}
      <div
        style={{
          height: '25px',
          width: '100%',
          background: 'linear-gradient(90deg, #E12426 0%, #C61F20 69%, #B41214 100%)',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 4,
        }}
      ></div>
        {/* Blurred background */}
        <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${backgroundImg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'bottom',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 1,
                }}
            >
        </div>
            {/* Dark overlay */}
            <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.70)',
                        backdropFilter: 'blur(40px)',
                        WebkitBackdropFilter: 'blur(37px)',
                        zIndex: 2,
                    }}
                >
            </div>
            <div
                className="container"
                style={{
                    position: 'relative',
                    zIndex: 3,
                }}
            >
                <div className="row align-items-lg-center">
                <div className="col-md-12 col-lg-12">
                        <Content classes="about-content">
                            <h2
                            ref={h2Ref}
							className={`heading who-are-we-h2 ${isHeadingVisible ? 'animate-slide-down' : ''}`}
                            style={{
                                color: "#ffffff",
                                textAlign: "center",
                                background: "linear-gradient(90deg, #E12426 0%, #C61F20 69%, #B41214 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                                textShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)"
                            }}>{parse(about.heading)}</h2>

                            <p
                            ref={pRef}
							className={`paragraph team-paragraph ${isParagraphVisible ? 'animate-slide-up' : ''}`}
                            style={{
                                textAlign: "center",
                                letterSpacing: "-0.44px"
                            }}
                            >
                            <span className="light-span">We are a team of</span>{" "}
                            <span className="bold-span">enthusiastic</span>{" "}
                            <span className="light-span">and</span>{" "}
                            <span className="bold-span">knowledgeable</span>{" "}
                            <span className="light-span">developers</span>{" "}<br />
                            <span className="light-span">who</span>{" "}
                            <span className="muted-span">seek to</span>{" "}
                            <span className="light-span">give</span>{" "}
                            <span className="bold-span">unique solutions</span>{" "}
                            <span className="muted-span">to tough challenges.</span>{" "}<br />
                            <span className="muted-span"> Our services encompass a wide number of disciplines,</span>{" "}
                            <span className="light-span">and</span>{" "}
                            <span className="muted-span">we</span>{" "}
                            <span className="light-span">take</span>{" "}<br />
                            <span className="light-span">pride in producing</span>{" "}
                            <span className="bold-span">high-quality products</span>.
                            </p>
                        </Content>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default About;